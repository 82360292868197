@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:700|Montserrat:600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F7F7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  /* text-align: center; */
  width: 100%;
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@-webkit-keyframes App-logo-spin { 
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin { 
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.elements {
  position: center;
  width: 100%;
}

#root {
  width: 100%;
}
.navBar {
  position: fixed;
  z-index : 2;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  background-color: #fff;
  color: #333;
  -webkit-transform: translateY(05);
          transform: translateY(05);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
}

.navBar.shadow {
  box-shadow: 0 9px 14px -9px rgba(0, 0, 0, 0.13);
}

.navBar.hidden {
  -webkit-transform: translateY(-110%);
          transform: translateY(-110%);
}

.navBar ul {
  display: flex;
  list-style: none;
  justify-content: center;
  width: 100%;
  left: 50%;
  position: center;
  text-align: center;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

.navBar ul a {
  margin: 0;
  text-decoration: none;
  color: #3c4046;
  display: block;
  padding: 20px;
  position: center;
}

.navBar ul a:hover {
  color: #ccaa7e;
  cursor: pointer;
}

.navBar ul .active {
  color: #dda04f;
}

.navItem {
  width: 100%;
  padding: 0;
  margin: 0;
}

#navId {
  width: 100%;
}

@media only screen and (max-width: 812px) {
  #navId {
    width: 100%;
    min-width: 0;
    max-width: 100%;
    font-size: 11px;
    height: 50px;
  }

  ul {
    width: 100%;
  }
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {

    #navId {
      font-size: 10px;
      height: 40px;
      width: 100%;
    }

    .navBar ul a {
      padding: 8px
    }

}
.home {
  background-color: #3c4046;
  width: 100%;
  min-width: 1400px;
  min-height: 47em;
  height: 100vh;
  max-height: 85em;
}

.homeComponents {
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: 'Lato', sans-serif;
}

.introText {
  text-align: left;
  width: 80%;
  float: left;
  margin: 0;
  color: #fcfcfc;
  white-space: nowrap;
  font-family: 'Open Sans', sans-serif;
}

.introText h1 {
  margin: 0;
  letter-spacing: 5px;
  font-size: 45px;
}

.introText h2 {
  margin: 0;
  padding-top: 10px;
  letter-spacing: 5px;
  font-size: 70px;
}

.introText h3 {
  margin: 0;
  letter-spacing: 1px;
  font-size: 30px;
}

.scrollImage {  
  position: absolute;  
  height: 35px;
  width: 65px;
}

.scrollButton {
  position: absolute;  
  top: 91%;
  left: 48%;
  mix-blend-mode: multiply;
  background: transparent;
}

.homeWrapper {
  align-items: center;
  display: flex;
}

.homeCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homeAlign {
  min-width: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
}

.letters {
  letter-spacing: 1px;
  font-size: 30px;
}

.ml11 .text-wrapper {
  position: relative;
  display: inline-block;
}

.ml11 .line {
  opacity: 0;
  position: absolute;
  margin-top: 24px;
  left: 0;
  height: 46%;
  width: 3px;
  background-color: #fff;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
}

.ml11 .line1 { 
  top: 0; 
  left: 0;
}

.ml11 .letter {
  display: inline-block;
  line-height: 1em;
}

.ml12 .text-wrapper {
  position: relative;
  display: inline-block;
}

.ml12 .line_1 {
  opacity: 0;
  position: absolute;
  margin-top: 24px;
  left: 0;
  height: 60%;
  width: 3px;
  background-color: #fff;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
}

.ml12 .line1 { 
  top: 0; 
  left: 0;
}

.ml12 .letter {
  display: inline-block;
  line-height: 1em;
  color: #dac3a5;
}

.ml11 .letter2 {
  display: inline-block;
  line-height: 1em;
}

.ml12 .letter2 {
  display: inline-block;
  line-height: 1em;
  color: #fff;
}

.letters2 {
  font-size: 50px;
  letter-spacing: 4px;
  font-size: 70px;
}

.letters3 {
  letter-spacing: 4px;
  font-size: 70px;
}

.ml11 {
  margin: 0;
  padding-bottom: 5px;
}

.ml12 {
  position: relative;
  margin: 0;
  padding-bottom: 50px;
  left: -3px;
}

.ml13 .text-wrapper {
  position: relative;
  display: inline-block;
}

.ml13 .line_2 {
  opacity: 0;
  position: absolute;
  margin-top: 12px;
  left: 0;
  height: 70%;
  width: 3px;
  background-color: #fff;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
}

.ml13 .line1 { 
  top: 0; 
  left: 0;
}

.ml13 .letter {
  display: inline-block;
  line-height: 1em;
  color: #fff;
}

.ml13 .letter5 {
  color: #dac3a5;
}

.ml13 .letter6 {
  color: #fff;
}

.ml13 .letter7 {
  color: #dac3a5;
}

.ml13 .letter8 {
  color: #fff;
}

@media only screen and (max-width: 812px) {
  .home {
    min-width: 0;
    height: 101vh;
    min-height: 35em;
  }

  .introText h1 {
    margin: 0;
    letter-spacing: 3px;
    font-size: 17px;
    width: 80%;
  }

  .letters {
    font-size: 20px;
  }

  .letters3{
    letter-spacing: 1px;
    font-size: 55px;
  }
  
  .letters2{
    letter-spacing: 1px;
    font-size: 55px;
  }

  .scrollImage {  
    position: absolute;  
    height: 22px;
    width: 42px;
  }

  .scrollButton {
    top: 93%;
    left: 44%;
  }

  .ml11 .line {
    margin-top: 3.5px;
    height: 80%;
  }

  .ml12 .line_1 {
    margin-top: 14px;
    height: 70%;
  }

  .ml13 .line_2 {
    margin-top: 3px;
    height: 85%;
  }

}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .introText h1 {
      font-size: 15px;
    }

    .letters {
      font-size: 18px;
    }

    .letters3{
      font-size: 50px;
    }
    
    .letters2{
      font-size: 50px;
    }
  
    .scrollImage {  
      height: 20px;
      width: 38px;
    }
}

.about {
    width: 100%;
    min-width: 1400px;
    min-height: 47em;
    max-height: 85em;
    height: 100vh;
    background-color: #d8c6ae;
    font-family: 'Roboto', sans-serif;
}

.aboutComponents {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10em;
    position: relative;
}

.titleAbout {
    width: 100%;
    text-align: left;
    color: #202020;
    padding-bottom: 3.5vh;
    font-family: 'Open Sans', sans-serif;
}

.aboutText h2 {
    line-height: 24px; 
    color: #3c4046;
    font-size: 18.5px;
}

.titleAlignAbout {
    min-width: 92%;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.titleAbout span {
    color: #000000;
    letter-spacing: 1px;
    font-size: 43px;
}

.portrait {
    width: 390px;
    height: auto;
}
  
.imageDiv {
    padding-left: 1.5em;
}

.aboutWrapper {
    align-items: center;
    display: flex;
}

.aboutCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutText {
    float: left;
    padding-top: 1vh;
    font-size: 13px;
    max-width: 700px;
}

.titleWrapper {
    align-items: center;
    display: flex;
}

.highlightedText {
    font-weight: 900;
    color: #ffffff
}

.aboutText h2 > a {
    position: relative;
    color: #ffffff;
    text-decoration: none;
  }
  
.aboutText h2 > a:hover {
    color: #ffffff;
}

.aboutText h2 > a::before {
    content: "";
    position: absolute;
    width: 98%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    visibility: hidden;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

.aboutText h2 > a:hover::before {
    visibility: visible;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
}

@media only screen and (min-width: 667px) and (max-width: 812px) {
    .about {
        min-width: 0;
        height: 130vh;
        min-height: 40em;
    }

    .portrait {
        padding: 8px 40px;
        padding-top: 15px;
        width: 180px;
    }

    .titleAbout {
        padding-bottom: 3px;
    }
    
    .titleAbout span {
        letter-spacing: 1px;
        font-size: 24px;
    }

    .aboutText h2 {
        line-height: 15px; 
        font-size: 12.5px;
    }

    .aboutText {
        display: block;
        float: center;
        padding-top: 1vh;
        font-size: 13px;
        width: 100%;
    }

    .imageDiv {
        padding-left: 0;
    }

    .aboutWrapper {
        display: inline;
    }

}

@media only screen and (max-width: 667px) {
    .about {
        min-width: 0;
        height: 130vh;
        min-height: 40em;
    }

    .portrait {
        padding: 8px 40px;
        padding-top: 15px;
        width: 180px;
    }

    .titleAbout {
        padding-bottom: 3px;
    }
    
    .titleAbout span {
        letter-spacing: 1px;
        font-size: 24px;
    }

    .aboutText h2 {
        line-height: 15px; 
        font-size: 12.5px;
    }

    .aboutText {
        display: block;
        float: center;
        padding-top: 1vh;
        font-size: 13px;
        width: 100%;
    }

    .imageDiv {
        padding-left: 0;
    }

    .aboutWrapper {
        display: inline;
    }

    
    .aboutText h2 {
        line-height: 15px; 
        font-size: 10.5px;
    }

    .portrait {
        padding: 8px 70px;
        padding-top: 15px;
        width: 170px;
        height: auto;
    }

    .titleAbout {
        padding-bottom: 0px;
    }
}

@media only screen 
and (min-device-width: 414px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .portrait {
        padding: 8px 60px;
        padding-top: 15px;
        width: 240px;
        height: auto;
    }

    .aboutText h2 {
        line-height: 15px; 
        font-size: 12.5px;
    }
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

    .portrait {
        padding: 8px 45px;
        padding-top: 15px;
        width: 240px;
        height: auto;
    }
}

@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2) {
    .about {
        min-height: 38em;
    }

    .portrait {
        padding: 8px 55px;
        padding-top: 15px;
        width: 180px;
    }
}      

.contact {
    background-color: #3c4046;
    width: 100%;
    min-width: 1400px;
    max-height: 85em;
    min-height: 47em;
    height: 100vh;
    font-family: 'Roboto', sans-serif;
}

.contactComponents {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10em;
    position: relative;
}

button {
    padding: 0;
    border: none;
    background: none;
    font-size: 40px;
    color: #d8c6ae;
}

.contactCenter {
    padding-top: 8em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactWrapper {
    align-items: center;
    display: flex;
}

.contactText {
    font-size: 13px;
    padding-top: 20px;
    float: left;
    text-align: center;
    color: #fcfcfc;
}

.contactText h1 {
    font-size: 24px;
}

.contactText h2 {
    font-size: 19px;
}

.searchTime {
    padding-top: 20px;
}

.email {
    font-size: 13px;
    padding-top: 20px;
}


.titleContacts{
    width: 100%;
    text-align: left;
    color: #fcfcfc;
    padding-bottom: 10vh;
    font-family: 'Open Sans', sans-serif;
}

.titleAlignContacts {
    min-width: 92%;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.titleContacts span {
    letter-spacing: 1px;
    font-size: 40px;
}

.resumeLink h2 > a {
    color: #d8c6ae;
}

.resumeLink h2 > a:hover {
    color: #d8c6ae;
    cursor: pointer
}
    
.resumeLink h2 > a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #d8c6ae;
    visibility: hidden;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

.resumeLink h2 > a:hover::before {
    visibility: visible;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
}

.email h2 > a {
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    padding-top: 20px;
    color: #d8c6ae;
}

.email h2 > a:hover {
    color: #d8c6ae;
    cursor: pointer
}
    
.email h2 > a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #d8c6ae;
    visibility: hidden;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

.email h2 > a:hover::before {
    visibility: visible;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
}

.iconImage {
    width: 50px;
    height: 50px;
    transition: all .12s ease-in-out; 
    transition: all .12s ease-in-out;
}

.iconImageLinkedin {
    width: 60px;
    height: 60px;
    transition: all .12s ease-in-out; 
    transition: all .12s ease-in-out;
}

.icons {
    display: block;
    position: center;
    align-items: center;
    width: 100%;
    margin-left: 18px;
    padding-top: 23px;
}

.icon {
    padding: 0 50px;
    display: inline;
    justify-content: center;
    width: 100%;
    position: center;
    text-align: center;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
}

.iconImageLinkedin:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.iconImage:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

@media only screen and (min-width: 667px) and (max-width: 812px) {
    .contact {
        min-width: 0;
        height: 100vh;
        min-height: 40em;
    }

    .titleContacts span {
        font-size: 28px;
    }

    .email {
        font-size: 20px;
        padding: 8px 120px;
    }

    .email h2 > a {
        font-size: 17px;
        left: -13px
    }

    .email h2 {
        margin: 0 30px;
        width: 75%;
    }

    .contactText h1 {
        font-size: 17px;
    }
    
    .contactText h2 {
        font-size: 17px;
    }
    
    .searchTime {
        padding-top: 2vh;
    }

    .iconImage {
        width: 30px;
        height: 30px;
    }

    .iconImageLinkedin {
        width: 38px;
        height: 38px;
    }
    
  }

  @media only screen and (max-width: 736px) {
    .contact {
        min-width: 0;
        height: 100vh;
        min-height: 40em;
    }

    .titleContacts span {
        font-size: 28px;
    }

    .email {
        padding: 8px 90px;
    }

    .email h2 > a {
        font-size: 17px;
        padding-top: 2vh;
    }

    .email h2 {
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
    }

    .contactText h1 {
        font-size: 17px;
    }
    
    .contactText h2 {
        font-size: 17px;
    }
    
    .searchTime {
        padding-top: 2vh;
    }

    .iconImage {
        width: 30px;
        height: 30px;
    }

    .iconImageLinkedin {
        width: 38px;
        height: 38px;
    }

    .icon {
        padding: 0 20px;
    }

    .icons {
        padding-top: 10px;
        margin-left: 0px;
    }
  }

@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2) {
    .email {
        text-align: center;
        padding: 8px 70px;
    }

    .email h2 > a {
        font-size: 17px;
        padding-top: 2vh;
    }

    .contactText h1 {
        font-size: 14px;
    }
    
    .contactText h2 {
        font-size: 14px;
    }

    .contact {
        min-height: 38em;
    }

    .titleContacts span {
        font-size: 24px;
    }
}      

.games {
    background-color: #d8c6ae;
    width: 100%;
    max-height: 85em;
    min-height: 72em;
    min-width: 1400px;
    height: 105vh;
    font-family: 'Roboto', sans-serif;
}

.gameComponents {
    height: 170;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6em 10em;
    position: relative;
}

.titleGames {
    width: 100%;
    text-align: left;
    color: #3c4046;
    padding-bottom: 3vh;
    font-family: 'Open Sans', sans-serif;
}

.titleAlignGames {
    min-width: 92%;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.titleGames span {
    color: #000000;
    letter-spacing: 1px;
    font-size: 40px;
}

.gameWrapper {
    align-items: center;
    display: flex;
}

.gameCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tile:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.tile {
    transition: -webkit-transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
}

.tileInner {
    height: 272px;
    width: 325px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #edeef1;
    cursor: pointer;
}

.tileTitle {
    font-weight: bold;
    font-size: 17px;
    margin-top: 1px;
    color: #6a7079;
}

.tileDescription {
    font-size: 13px;
    margin: 10px;
    bottom: 0;
    color: #3c4046;
}

.tileImage {
    height: 73%;
    width: 100%;
    background-size: cover;
    background-position: center;
    margin: 13px;
    margin-top: 0;
}

.popup-enter {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

.popup-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}
.popup-exit {
    opacity: 1;
}
.popup-exit-active {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.gridContainer {
    width: 100%;
    top: 25vh;
    height: 75vh;
}

.gridWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gridContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.gridTile {
    padding-top: 2vh;
    display: flex;
    flex-direction: row;
}

.overlayComponent {
    margin-left: -11%;
    margin-top: 50px;
    background-color: none;
    outline: none;
    position: absolute;
    justify-content: center;
    display: flex;
    position: center;
    width: 1350px;
    height: 90vh;
    background-color: #edeef1;
}

.exitButton {
    z-index: 1;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    position: absolute;
    top: 1%;
    left: 96%;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #3c4046;
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .games {
        min-width: 0;
        height: 100vh;
        min-height: 40em;
    }

    .gameComponents {
        padding: 0em 10em;
    }

    .tileTitle {
        font-weight: bold;
        font-size: 11px;
        margin-top: -6px;
        color: #6a7079;
    }

    .tileDescription {
        font-size: 9px;
        margin: 3px;
        bottom: 0;
        color: #3c4046;
    }

    .tileInner {
        height: 150px;
        width: 160px;
        margin: 8px;
    }

    .titleGames span {
        font-size: 24px;
    }

    .exitButton {
        top: 1%;
        left: 93%;
        font-size: 25px;
    }
  
}

@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .games {
        min-width: 0;
        height: 100vh;
        min-height: 40em;
    }

    .gameComponents {
        padding: 0em 10em;
    }

    .tileTitle {
        font-weight: bold;
        font-size: 11px;
        margin-top: -6px;
        color: #6a7079;
    }

    .tileDescription {
        font-size: 9px;
        margin: 3px;
        bottom: 0;
        color: #3c4046;
    }

    .tileInner {
        height: 150px;
        width: 160px;
        margin: 8px;
    }

    .titleGames span {
        font-size: 24px;
    }

    .exitButton {
        top: 1%;
        left: 93%;
        font-size: 25px;
    }
  
  }

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2) { 
    .games {
        min-width: 0;
        height: 120vh;
        min-height: 40em;
    }

    .gameComponents {
        padding: 0em 10em;
    }

    .tileTitle {
        font-weight: bold;
        font-size: 11px;
        margin-top: -6px;
        color: #6a7079;
    }

    .tileDescription {
        font-size: 9px;
        margin: 3px;
        bottom: 0;
        color: #3c4046;
    }

    .tileInner {
        height: 150px;
        width: 160px;
        margin: 8px;
    }

    .titleGames span {
        font-size: 24px;
    }

    .exitButton {
        top: 1%;
        left: 93%;
        font-size: 25px;
    }
  
  }

  @media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .games {
        min-width: 0;
        height: 120vh;
        min-height: 40em;
    }

    .overlayComponent {
        margin-left: -6%;
        margin-top: 50px;
        width: 90%;
        height: 93vh;
        background-color: #edeef1;
    }

    .gameComponents {
        padding: 2em 10em;
    }

    .tileTitle {
        font-weight: bold;
        font-size: 10px;
        margin-top: -6px;
        color: #6a7079;
    }

    .tileDescription {
        font-size: 7.5px;
        margin: 3px;
        bottom: 0;
        color: #3c4046;
    }

    .tileInner {
        height: 135px;
        width: 140px;
        margin: 8px;
    }

    .titleGames span {
        font-size: 24px;
    }

    .exitButton {
        top: 1%;
        left: 93%;
        font-size: 25px;
    }
}

@media only screen 
and (min-device-width: 375.1px) 
and (max-device-width: 414px) 
and (-webkit-min-device-pixel-ratio: 2) { 
    .overlayComponent {
        margin-left: -6%;
    }

    .games {
        height: 105vh;
    }
}

@media only screen 
and (min-device-width: 360.1px) 
and (max-device-width: 375px) 
and (-webkit-min-device-pixel-ratio: 2) { 
    .overlayComponent {
        margin-left: -2.5%;
    }

    .games {
        height: 100vh;
    }
}

@media only screen 
and (min-device-width: 320.1px) 
and (max-device-width: 360px) 
and (-webkit-min-device-pixel-ratio: 2) { 
    .overlayComponent {
        margin-left: -2.5%;
    }

    .games {
        height: 120vh;
    }
}

@media only screen 
and (min-device-width: 0px) 
and (max-device-width: 320px) 
and (-webkit-min-device-pixel-ratio: 2) { 
    .overlayComponent {
        margin-left: 5%;
    }
    .games {
        height: 130vh;
    }
}
.gameContext {
    font-size: 15px;
    position: relative;
    height: 100%;
    border: none;
    width: 100%;
    outline: none;
    overflow: auto;
    display: flex;
    justify-content: center;
    color: black;
    line-height: 25px; 
}

.gameContext h2 {
    padding-top: 30px;
    padding-bottom: 8px;
    font-size: 27px;
}

.gameContext h3 {
    font-size: 17px;
}

.gameInfoWrapper {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    padding: 4vh 3vh;
}

.gameTitle {
    text-align: center;
    font-size: 48px;
}

.lastParagraph {
    padding-bottom: 4vh;
}

.youtubeVideo {
    position: relative;
    padding-bottom: 53.25%;
    padding-top: 10;
    height: 0;
}

.iFrame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.gameLink {
    text-decoration: none;
}

.solitaireImage1 {
    margin: 0 50px;
    margin-top: 15px;
    padding-bottom: 15px;
    height: 210px;
    width: 170px;
}

.solitaireImage2 {
    margin: 0 50px;
    margin-top: 0px;
    height: 215px;
    width: 440px;
}

.breakoutImage {
    margin: 0 20px;
    margin-top: 40px;
    padding-bottom: 15px;
    height: 210px;
    width: 300px;
}

.imagesContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 0;
}

.imagesContainerMarble {
    display: flex;
    justify-content: center;
    padding-bottom: 0;
}

.industrialGif {
    height: 690px;
    width: 1200px;
}

.forTraumArt1 {
    height: 700px;
    width: 1250px;
}

.forTraumArt3 {
    height: 1283px;
    width: 700px;
}

.forTraumArt4 {
    height: 753px;
    width: 750px;
}

.forTraumArt5 {
    height: 761px;
    width: 920px;
}

.forTraumArt6 {
    height: 666px;
    width: 800px;
}

.art h3 {
    text-align: center;
    padding-bottom: 15px;
}

@media only screen and (max-width: 812px) {
    .gameTitle {
        font-size: 32px;
    }
    
    .gameContext h2 {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 18px;
    }
    
    .gameContext h3 {
        font-size: 12px;
    }

    .industrialGif {
        height: 172px;
        width: 300px;
    }
    
    .forTraumArt1 {
        height: 172px;
        width: 300px;
    }
    
    .forTraumArt3 {
        height: 550px;
        width: 300px;
    }
    
    .forTraumArt4 {
        height: 303px;
        width: 300px;
    }
    
    .forTraumArt5 {
        height: 248px;
        width: 300px;
    }
    
    .forTraumArt6 {
        height: 249px;
        width: 300px;
    }

    .imagesContainerMarble {
        display: inline;
        justify-content: none;
    }

    .solitaireImage1 {
        margin: 0 95px;
        margin-top: 0px;
        padding-bottom: 3px;
        height: 123px;
        width: 100px;
    }
    
    .solitaireImage2 {
        margin: 0 30px;
        margin-top: 0px;
        height: 120px;
        width: 230px;
    }
}

.projects {
    background-color: #3c4046;
    width: 100%;
    max-height: 85em;
    min-height: 52em;
    min-width: 1400px;
    height: 105vh;
    font-family: 'Roboto', sans-serif;
}

.projectsComponents {
    height: 170;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6em 10em;
    position: relative;
}

.titleProjects {
    width: 100%;
    text-align: left;
    color: #fcfcfc;
    padding-bottom: 3vh;
    font-family: 'Open Sans', sans-serif;
}

.titleAlignProjects {
    min-width: 92%;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.titleProjects span {
    letter-spacing: 1px;
    font-size: 40px;
}

.projectsWrapper {
    align-items: center;
    display: flex;
}

.projectsCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tileInnerProject {
    height: 272px;
    width: 325px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    background: #edeef1;
}


@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .projects {
        min-width: 0;
        height: 100vh;
        min-height: 40em;
    }

    .projectsComponents {
        padding: 0em 10em;
    }
    
    .tileInnerProject {
        height: 150px;
        width: 160px;
        margin: 8px;
    }

    .titleProjects span {
        font-size: 24px;
    }
  }

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2) {
    .projects {
        min-width: 0;
        height: 100vh;
        min-height: 40em;
    }

    .projectsComponents {
        padding: 0em 10em;
    }
    
    .tileInnerProject {
        height: 150px;
        width: 160px;
        margin: 8px;
    }

    .titleProjects span {
        font-size: 24px;
    }
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .projects {
        min-width: 0;
        height: 100vh;
        min-height: 40em;
    }

    .projectsComponents {
        padding: 3em 10em;
    }
    
    .tileInnerProject {
        height: 135px;
        width: 140px;
        margin: 8px;
    }

    .titleProjects span {
        font-size: 24px;
    }
}
.projectContext {
    font-size: 15px;
    position: relative;
    height: 100%;
    border: none;
    width: 100%;
    outline: none;
    overflow: auto;
    display: flex;
    justify-content: center;
    color: black;
    line-height: 25px; 
}

.projectContext h2 {
    padding-top: 30px;
    padding-bottom: 8px;
    font-size: 27px;
}

.projectContext h3 {
    font-size: 17px;
}

.projectInfoWrapper {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    padding: 4vh 3vh;
}

.projectTitle {
    text-align: center;
    font-size: 48px;
}

.lastParagraph {
    padding-bottom: 4vh;
}

.youtubeVideo {
    margin-top: 40px;
    margin-bottom: -120px;
    align-items: center;
    text-align: center;
    display: block;
    justify-content: center;
    margin-left: 165px;
}

.iFrame {
    width: 903px;
    height: 580px;
    align-items: center;
    text-align: center;
}

h2 > a {
    position: relative;
    color: #000;
    text-decoration: none;
}
  
h2 > a:hover {
color: #000;
}

h2 > a::before {
content: "";
position: absolute;
width: 100%;
height: 2px;
bottom: 0;
left: 0;
background-color: #000;
visibility: hidden;
-webkit-transform: scaleX(0);
        transform: scaleX(0);
transition: all 0.3s ease-in-out 0s;
visibility: visible;
-webkit-transform: scaleX(1);
        transform: scaleX(1);
}

h2 > a:hover::before {
visibility: visible;

-webkit-transform: scaleX(1);

        transform: scaleX(1);
}

.projectLink {
    text-decoration: none;
}

@media only screen and (max-width: 812px) {
    h2 > a {
        text-decoration: underline;
    }
      
    h2 > a::before {
        visibility: visible;
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
    }

    .youtubeVideo {
        padding-bottom: 270px;
        margin-top: 20px;
        align-items: center;
        text-align: center;
        display: block;
        justify-content: center;
        margin-left: 0;
    }
    
    .iFrame {
        width: 260px;
        height: 145px;
        align-items: center;
        text-align: center;
    }

    .lastParagraph {
        padding-bottom: 15px;
    }

    .projectTitle {
        font-size: 32px;
    }

    .projectContext h2 {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 18px;
    }
    
    .projectContext h3 {
        font-size: 12px;
    }
}
