.projectContext {
    font-size: 15px;
    position: relative;
    height: 100%;
    border: none;
    width: 100%;
    outline: none;
    overflow: auto;
    display: flex;
    justify-content: center;
    color: black;
    line-height: 25px; 
}

.projectContext h2 {
    padding-top: 30px;
    padding-bottom: 8px;
    font-size: 27px;
}

.projectContext h3 {
    font-size: 17px;
}

.projectInfoWrapper {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    padding: 4vh 3vh;
}

.projectTitle {
    text-align: center;
    font-size: 48px;
}

.lastParagraph {
    padding-bottom: 4vh;
}

.youtubeVideo {
    margin-top: 40px;
    margin-bottom: -120px;
    align-items: center;
    text-align: center;
    display: block;
    justify-content: center;
    margin-left: 165px;
}

.iFrame {
    width: 903px;
    height: 580px;
    align-items: center;
    text-align: center;
}

h2 > a {
    position: relative;
    color: #000;
    text-decoration: none;
}
  
h2 > a:hover {
color: #000;
}

h2 > a::before {
content: "";
position: absolute;
width: 100%;
height: 2px;
bottom: 0;
left: 0;
background-color: #000;
visibility: hidden;
transform: scaleX(0);
transition: all 0.3s ease-in-out 0s;
visibility: visible;
transform: scaleX(1);
}

h2 > a:hover::before {
visibility: visible;

transform: scaleX(1);
}

.projectLink {
    text-decoration: none;
}

@media only screen and (max-width: 812px) {
    h2 > a {
        text-decoration: underline;
    }
      
    h2 > a::before {
        visibility: visible;
        transform: scaleX(1);
    }

    .youtubeVideo {
        padding-bottom: 270px;
        margin-top: 20px;
        align-items: center;
        text-align: center;
        display: block;
        justify-content: center;
        margin-left: 0;
    }
    
    .iFrame {
        width: 260px;
        height: 145px;
        align-items: center;
        text-align: center;
    }

    .lastParagraph {
        padding-bottom: 15px;
    }

    .projectTitle {
        font-size: 32px;
    }

    .projectContext h2 {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 18px;
    }
    
    .projectContext h3 {
        font-size: 12px;
    }
}