@import url('https://fonts.googleapis.com/css?family=Lato:700|Montserrat:600&display=swap');

.contact {
    background-color: #3c4046;
    width: 100%;
    min-width: 1400px;
    max-height: 85em;
    min-height: 47em;
    height: 100vh;
    font-family: 'Roboto', sans-serif;
}

.contactComponents {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10em;
    position: relative;
}

button {
    padding: 0;
    border: none;
    background: none;
    font-size: 40px;
    color: #d8c6ae;
}

.contactCenter {
    padding-top: 8em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactWrapper {
    align-items: center;
    display: flex;
}

.contactText {
    font-size: 13px;
    padding-top: 20px;
    float: left;
    text-align: center;
    color: #fcfcfc;
}

.contactText h1 {
    font-size: 24px;
}

.contactText h2 {
    font-size: 19px;
}

.searchTime {
    padding-top: 20px;
}

.email {
    font-size: 13px;
    padding-top: 20px;
}


.titleContacts{
    width: 100%;
    text-align: left;
    color: #fcfcfc;
    padding-bottom: 10vh;
    font-family: 'Open Sans', sans-serif;
}

.titleAlignContacts {
    min-width: 92%;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.titleContacts span {
    letter-spacing: 1px;
    font-size: 40px;
}

.resumeLink h2 > a {
    color: #d8c6ae;
}

.resumeLink h2 > a:hover {
    color: #d8c6ae;
    cursor: pointer
}
    
.resumeLink h2 > a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #d8c6ae;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

.resumeLink h2 > a:hover::before {
    visibility: visible;
    transform: scaleX(1);
}

.email h2 > a {
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    padding-top: 20px;
    color: #d8c6ae;
}

.email h2 > a:hover {
    color: #d8c6ae;
    cursor: pointer
}
    
.email h2 > a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #d8c6ae;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

.email h2 > a:hover::before {
    visibility: visible;
    transform: scaleX(1);
}

.iconImage {
    width: 50px;
    height: 50px;
    transition: all .12s ease-in-out;
    -webkit-transition: all .12s ease-in-out; 
    -moz-transition: all .12s ease-in-out; 
    -o-transition: all .12s ease-in-out; 
    transition: all .12s ease-in-out;
}

.iconImageLinkedin {
    width: 60px;
    height: 60px;
    transition: all .12s ease-in-out;
    -webkit-transition: all .12s ease-in-out; 
    -moz-transition: all .12s ease-in-out; 
    -o-transition: all .12s ease-in-out; 
    transition: all .12s ease-in-out;
}

.icons {
    display: block;
    position: center;
    align-items: center;
    width: 100%;
    margin-left: 18px;
    padding-top: 23px;
}

.icon {
    padding: 0 50px;
    display: inline;
    justify-content: center;
    width: 100%;
    position: center;
    text-align: center;
    padding-inline-start: 0px;
}

.iconImageLinkedin:hover {
    cursor: pointer;
    transform: scale(1.2);
}

.iconImage:hover {
    cursor: pointer;
    transform: scale(1.2);
}

@media only screen and (min-width: 667px) and (max-width: 812px) {
    .contact {
        min-width: 0;
        height: 100vh;
        min-height: 40em;
    }

    .titleContacts span {
        font-size: 28px;
    }

    .email {
        font-size: 20px;
        padding: 8px 120px;
    }

    .email h2 > a {
        font-size: 17px;
        left: -13px
    }

    .email h2 {
        margin: 0 30px;
        width: 75%;
    }

    .contactText h1 {
        font-size: 17px;
    }
    
    .contactText h2 {
        font-size: 17px;
    }
    
    .searchTime {
        padding-top: 2vh;
    }

    .iconImage {
        width: 30px;
        height: 30px;
    }

    .iconImageLinkedin {
        width: 38px;
        height: 38px;
    }
    
  }

  @media only screen and (max-width: 736px) {
    .contact {
        min-width: 0;
        height: 100vh;
        min-height: 40em;
    }

    .titleContacts span {
        font-size: 28px;
    }

    .email {
        padding: 8px 90px;
    }

    .email h2 > a {
        font-size: 17px;
        padding-top: 2vh;
    }

    .email h2 {
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
    }

    .contactText h1 {
        font-size: 17px;
    }
    
    .contactText h2 {
        font-size: 17px;
    }
    
    .searchTime {
        padding-top: 2vh;
    }

    .iconImage {
        width: 30px;
        height: 30px;
    }

    .iconImageLinkedin {
        width: 38px;
        height: 38px;
    }

    .icon {
        padding: 0 20px;
    }

    .icons {
        padding-top: 10px;
        margin-left: 0px;
    }
  }

@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2) {
    .email {
        text-align: center;
        padding: 8px 70px;
    }

    .email h2 > a {
        font-size: 17px;
        padding-top: 2vh;
    }

    .contactText h1 {
        font-size: 14px;
    }
    
    .contactText h2 {
        font-size: 14px;
    }

    .contact {
        min-height: 38em;
    }

    .titleContacts span {
        font-size: 24px;
    }
}      
