.games {
    background-color: #d8c6ae;
    width: 100%;
    max-height: 85em;
    min-height: 72em;
    min-width: 1400px;
    height: 105vh;
    font-family: 'Roboto', sans-serif;
}

.gameComponents {
    height: 170;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6em 10em;
    position: relative;
}

.titleGames {
    width: 100%;
    text-align: left;
    color: #3c4046;
    padding-bottom: 3vh;
    font-family: 'Open Sans', sans-serif;
}

.titleAlignGames {
    min-width: 92%;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.titleGames span {
    color: #000000;
    letter-spacing: 1px;
    font-size: 40px;
}

.gameWrapper {
    align-items: center;
    display: flex;
}

.gameCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tile:hover {
    transform: scale(1.05);
}

.tile {
    transition: transform .2s ease;
}

.tileInner {
    height: 272px;
    width: 325px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #edeef1;
    cursor: pointer;
}

.tileTitle {
    font-weight: bold;
    font-size: 17px;
    margin-top: 1px;
    color: #6a7079;
}

.tileDescription {
    font-size: 13px;
    margin: 10px;
    bottom: 0;
    color: #3c4046;
}

.tileImage {
    height: 73%;
    width: 100%;
    background-size: cover;
    background-position: center;
    margin: 13px;
    margin-top: 0;
}

.popup-enter {
    opacity: 0;
    transform: scale(0.9);
}

.popup-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.popup-exit {
    opacity: 1;
}
.popup-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.gridContainer {
    width: 100%;
    top: 25vh;
    height: 75vh;
}

.gridWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gridContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.gridTile {
    padding-top: 2vh;
    display: flex;
    flex-direction: row;
}

.overlayComponent {
    margin-left: -11%;
    margin-top: 50px;
    background-color: none;
    outline: none;
    position: absolute;
    justify-content: center;
    display: flex;
    position: center;
    width: 1350px;
    height: 90vh;
    background-color: #edeef1;
}

.exitButton {
    z-index: 1;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    position: absolute;
    top: 1%;
    left: 96%;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #3c4046;
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .games {
        min-width: 0;
        height: 100vh;
        min-height: 40em;
    }

    .gameComponents {
        padding: 0em 10em;
    }

    .tileTitle {
        font-weight: bold;
        font-size: 11px;
        margin-top: -6px;
        color: #6a7079;
    }

    .tileDescription {
        font-size: 9px;
        margin: 3px;
        bottom: 0;
        color: #3c4046;
    }

    .tileInner {
        height: 150px;
        width: 160px;
        margin: 8px;
    }

    .titleGames span {
        font-size: 24px;
    }

    .exitButton {
        top: 1%;
        left: 93%;
        font-size: 25px;
    }
  
}

@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .games {
        min-width: 0;
        height: 100vh;
        min-height: 40em;
    }

    .gameComponents {
        padding: 0em 10em;
    }

    .tileTitle {
        font-weight: bold;
        font-size: 11px;
        margin-top: -6px;
        color: #6a7079;
    }

    .tileDescription {
        font-size: 9px;
        margin: 3px;
        bottom: 0;
        color: #3c4046;
    }

    .tileInner {
        height: 150px;
        width: 160px;
        margin: 8px;
    }

    .titleGames span {
        font-size: 24px;
    }

    .exitButton {
        top: 1%;
        left: 93%;
        font-size: 25px;
    }
  
  }

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2) { 
    .games {
        min-width: 0;
        height: 120vh;
        min-height: 40em;
    }

    .gameComponents {
        padding: 0em 10em;
    }

    .tileTitle {
        font-weight: bold;
        font-size: 11px;
        margin-top: -6px;
        color: #6a7079;
    }

    .tileDescription {
        font-size: 9px;
        margin: 3px;
        bottom: 0;
        color: #3c4046;
    }

    .tileInner {
        height: 150px;
        width: 160px;
        margin: 8px;
    }

    .titleGames span {
        font-size: 24px;
    }

    .exitButton {
        top: 1%;
        left: 93%;
        font-size: 25px;
    }
  
  }

  @media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .games {
        min-width: 0;
        height: 120vh;
        min-height: 40em;
    }

    .overlayComponent {
        margin-left: -6%;
        margin-top: 50px;
        width: 90%;
        height: 93vh;
        background-color: #edeef1;
    }

    .gameComponents {
        padding: 2em 10em;
    }

    .tileTitle {
        font-weight: bold;
        font-size: 10px;
        margin-top: -6px;
        color: #6a7079;
    }

    .tileDescription {
        font-size: 7.5px;
        margin: 3px;
        bottom: 0;
        color: #3c4046;
    }

    .tileInner {
        height: 135px;
        width: 140px;
        margin: 8px;
    }

    .titleGames span {
        font-size: 24px;
    }

    .exitButton {
        top: 1%;
        left: 93%;
        font-size: 25px;
    }
}

@media only screen 
and (min-device-width: 375.1px) 
and (max-device-width: 414px) 
and (-webkit-min-device-pixel-ratio: 2) { 
    .overlayComponent {
        margin-left: -6%;
    }

    .games {
        height: 105vh;
    }
}

@media only screen 
and (min-device-width: 360.1px) 
and (max-device-width: 375px) 
and (-webkit-min-device-pixel-ratio: 2) { 
    .overlayComponent {
        margin-left: -2.5%;
    }

    .games {
        height: 100vh;
    }
}

@media only screen 
and (min-device-width: 320.1px) 
and (max-device-width: 360px) 
and (-webkit-min-device-pixel-ratio: 2) { 
    .overlayComponent {
        margin-left: -2.5%;
    }

    .games {
        height: 120vh;
    }
}

@media only screen 
and (min-device-width: 0px) 
and (max-device-width: 320px) 
and (-webkit-min-device-pixel-ratio: 2) { 
    .overlayComponent {
        margin-left: 5%;
    }
    .games {
        height: 130vh;
    }
}