.about {
    width: 100%;
    min-width: 1400px;
    min-height: 47em;
    max-height: 85em;
    height: 100vh;
    background-color: #d8c6ae;
    font-family: 'Roboto', sans-serif;
}

.aboutComponents {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10em;
    position: relative;
}

.titleAbout {
    width: 100%;
    text-align: left;
    color: #202020;
    padding-bottom: 3.5vh;
    font-family: 'Open Sans', sans-serif;
}

.aboutText h2 {
    line-height: 24px; 
    color: #3c4046;
    font-size: 18.5px;
}

.titleAlignAbout {
    min-width: 92%;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.titleAbout span {
    color: #000000;
    letter-spacing: 1px;
    font-size: 43px;
}

.portrait {
    width: 390px;
    height: auto;
}
  
.imageDiv {
    padding-left: 1.5em;
}

.aboutWrapper {
    align-items: center;
    display: flex;
}

.aboutCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutText {
    float: left;
    padding-top: 1vh;
    font-size: 13px;
    max-width: 700px;
}

.titleWrapper {
    align-items: center;
    display: flex;
}

.highlightedText {
    font-weight: 900;
    color: #ffffff
}

.aboutText h2 > a {
    position: relative;
    color: #ffffff;
    text-decoration: none;
  }
  
.aboutText h2 > a:hover {
    color: #ffffff;
}

.aboutText h2 > a::before {
    content: "";
    position: absolute;
    width: 98%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

.aboutText h2 > a:hover::before {
    visibility: visible;
    transform: scaleX(1);
}

@media only screen and (min-width: 667px) and (max-width: 812px) {
    .about {
        min-width: 0;
        height: 130vh;
        min-height: 40em;
    }

    .portrait {
        padding: 8px 40px;
        padding-top: 15px;
        width: 180px;
    }

    .titleAbout {
        padding-bottom: 3px;
    }
    
    .titleAbout span {
        letter-spacing: 1px;
        font-size: 24px;
    }

    .aboutText h2 {
        line-height: 15px; 
        font-size: 12.5px;
    }

    .aboutText {
        display: block;
        float: center;
        padding-top: 1vh;
        font-size: 13px;
        width: 100%;
    }

    .imageDiv {
        padding-left: 0;
    }

    .aboutWrapper {
        display: inline;
    }

}

@media only screen and (max-width: 667px) {
    .about {
        min-width: 0;
        height: 130vh;
        min-height: 40em;
    }

    .portrait {
        padding: 8px 40px;
        padding-top: 15px;
        width: 180px;
    }

    .titleAbout {
        padding-bottom: 3px;
    }
    
    .titleAbout span {
        letter-spacing: 1px;
        font-size: 24px;
    }

    .aboutText h2 {
        line-height: 15px; 
        font-size: 12.5px;
    }

    .aboutText {
        display: block;
        float: center;
        padding-top: 1vh;
        font-size: 13px;
        width: 100%;
    }

    .imageDiv {
        padding-left: 0;
    }

    .aboutWrapper {
        display: inline;
    }

    
    .aboutText h2 {
        line-height: 15px; 
        font-size: 10.5px;
    }

    .portrait {
        padding: 8px 70px;
        padding-top: 15px;
        width: 170px;
        height: auto;
    }

    .titleAbout {
        padding-bottom: 0px;
    }
}

@media only screen 
and (min-device-width: 414px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .portrait {
        padding: 8px 60px;
        padding-top: 15px;
        width: 240px;
        height: auto;
    }

    .aboutText h2 {
        line-height: 15px; 
        font-size: 12.5px;
    }
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

    .portrait {
        padding: 8px 45px;
        padding-top: 15px;
        width: 240px;
        height: auto;
    }
}

@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2) {
    .about {
        min-height: 38em;
    }

    .portrait {
        padding: 8px 55px;
        padding-top: 15px;
        width: 180px;
    }
}      
