.gameContext {
    font-size: 15px;
    position: relative;
    height: 100%;
    border: none;
    width: 100%;
    outline: none;
    overflow: auto;
    display: flex;
    justify-content: center;
    color: black;
    line-height: 25px; 
}

.gameContext h2 {
    padding-top: 30px;
    padding-bottom: 8px;
    font-size: 27px;
}

.gameContext h3 {
    font-size: 17px;
}

.gameInfoWrapper {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    padding: 4vh 3vh;
}

.gameTitle {
    text-align: center;
    font-size: 48px;
}

.lastParagraph {
    padding-bottom: 4vh;
}

.youtubeVideo {
    position: relative;
    padding-bottom: 53.25%;
    padding-top: 10;
    height: 0;
}

.iFrame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.gameLink {
    text-decoration: none;
}

.solitaireImage1 {
    margin: 0 50px;
    margin-top: 15px;
    padding-bottom: 15px;
    height: 210px;
    width: 170px;
}

.solitaireImage2 {
    margin: 0 50px;
    margin-top: 0px;
    height: 215px;
    width: 440px;
}

.breakoutImage {
    margin: 0 20px;
    margin-top: 40px;
    padding-bottom: 15px;
    height: 210px;
    width: 300px;
}

.imagesContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 0;
}

.imagesContainerMarble {
    display: flex;
    justify-content: center;
    padding-bottom: 0;
}

.industrialGif {
    height: 690px;
    width: 1200px;
}

.forTraumArt1 {
    height: 700px;
    width: 1250px;
}

.forTraumArt3 {
    height: 1283px;
    width: 700px;
}

.forTraumArt4 {
    height: 753px;
    width: 750px;
}

.forTraumArt5 {
    height: 761px;
    width: 920px;
}

.forTraumArt6 {
    height: 666px;
    width: 800px;
}

.art h3 {
    text-align: center;
    padding-bottom: 15px;
}

@media only screen and (max-width: 812px) {
    .gameTitle {
        font-size: 32px;
    }
    
    .gameContext h2 {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 18px;
    }
    
    .gameContext h3 {
        font-size: 12px;
    }

    .industrialGif {
        height: 172px;
        width: 300px;
    }
    
    .forTraumArt1 {
        height: 172px;
        width: 300px;
    }
    
    .forTraumArt3 {
        height: 550px;
        width: 300px;
    }
    
    .forTraumArt4 {
        height: 303px;
        width: 300px;
    }
    
    .forTraumArt5 {
        height: 248px;
        width: 300px;
    }
    
    .forTraumArt6 {
        height: 249px;
        width: 300px;
    }

    .imagesContainerMarble {
        display: inline;
        justify-content: none;
    }

    .solitaireImage1 {
        margin: 0 95px;
        margin-top: 0px;
        padding-bottom: 3px;
        height: 123px;
        width: 100px;
    }
    
    .solitaireImage2 {
        margin: 0 30px;
        margin-top: 0px;
        height: 120px;
        width: 230px;
    }
}
