.navBar {
  position: fixed;
  z-index : 2;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  background-color: #fff;
  color: #333;
  transform: translateY(05);
  transition: transform 0.3s ease;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
}

.navBar.shadow {
  box-shadow: 0 9px 14px -9px rgba(0, 0, 0, 0.13);
}

.navBar.hidden {
  transform: translateY(-110%);
}

.navBar ul {
  display: flex;
  list-style: none;
  justify-content: center;
  width: 100%;
  left: 50%;
  position: center;
  text-align: center;
  padding-inline-start: 0px;
}

.navBar ul a {
  margin: 0;
  text-decoration: none;
  color: #3c4046;
  display: block;
  padding: 20px;
  position: center;
}

.navBar ul a:hover {
  color: #ccaa7e;
  cursor: pointer;
}

.navBar ul .active {
  color: #dda04f;
}

.navItem {
  width: 100%;
  padding: 0;
  margin: 0;
}

#navId {
  width: 100%;
}

@media only screen and (max-width: 812px) {
  #navId {
    width: 100%;
    min-width: 0;
    max-width: 100%;
    font-size: 11px;
    height: 50px;
  }

  ul {
    width: 100%;
  }
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {

    #navId {
      font-size: 10px;
      height: 40px;
      width: 100%;
    }

    .navBar ul a {
      padding: 8px
    }

}