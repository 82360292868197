.projects {
    background-color: #3c4046;
    width: 100%;
    max-height: 85em;
    min-height: 52em;
    min-width: 1400px;
    height: 105vh;
    font-family: 'Roboto', sans-serif;
}

.projectsComponents {
    height: 170;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6em 10em;
    position: relative;
}

.titleProjects {
    width: 100%;
    text-align: left;
    color: #fcfcfc;
    padding-bottom: 3vh;
    font-family: 'Open Sans', sans-serif;
}

.titleAlignProjects {
    min-width: 92%;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.titleProjects span {
    letter-spacing: 1px;
    font-size: 40px;
}

.projectsWrapper {
    align-items: center;
    display: flex;
}

.projectsCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tileInnerProject {
    height: 272px;
    width: 325px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    background: #edeef1;
}


@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .projects {
        min-width: 0;
        height: 100vh;
        min-height: 40em;
    }

    .projectsComponents {
        padding: 0em 10em;
    }
    
    .tileInnerProject {
        height: 150px;
        width: 160px;
        margin: 8px;
    }

    .titleProjects span {
        font-size: 24px;
    }
  }

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2) {
    .projects {
        min-width: 0;
        height: 100vh;
        min-height: 40em;
    }

    .projectsComponents {
        padding: 0em 10em;
    }
    
    .tileInnerProject {
        height: 150px;
        width: 160px;
        margin: 8px;
    }

    .titleProjects span {
        font-size: 24px;
    }
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .projects {
        min-width: 0;
        height: 100vh;
        min-height: 40em;
    }

    .projectsComponents {
        padding: 3em 10em;
    }
    
    .tileInnerProject {
        height: 135px;
        width: 140px;
        margin: 8px;
    }

    .titleProjects span {
        font-size: 24px;
    }
}