@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.home {
  background-color: #3c4046;
  width: 100%;
  min-width: 1400px;
  min-height: 47em;
  height: 100vh;
  max-height: 85em;
}

.homeComponents {
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: 'Lato', sans-serif;
}

.introText {
  text-align: left;
  width: 80%;
  float: left;
  margin: 0;
  color: #fcfcfc;
  white-space: nowrap;
  font-family: 'Open Sans', sans-serif;
}

.introText h1 {
  margin: 0;
  letter-spacing: 5px;
  font-size: 45px;
}

.introText h2 {
  margin: 0;
  padding-top: 10px;
  letter-spacing: 5px;
  font-size: 70px;
}

.introText h3 {
  margin: 0;
  letter-spacing: 1px;
  font-size: 30px;
}

.scrollImage {  
  position: absolute;  
  height: 35px;
  width: 65px;
}

.scrollButton {
  position: absolute;  
  top: 91%;
  left: 48%;
  mix-blend-mode: multiply;
  background: transparent;
}

.homeWrapper {
  align-items: center;
  display: flex;
}

.homeCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homeAlign {
  min-width: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
}

.letters {
  letter-spacing: 1px;
  font-size: 30px;
}

.ml11 .text-wrapper {
  position: relative;
  display: inline-block;
}

.ml11 .line {
  opacity: 0;
  position: absolute;
  margin-top: 24px;
  left: 0;
  height: 46%;
  width: 3px;
  background-color: #fff;
  transform-origin: 0 50%;
}

.ml11 .line1 { 
  top: 0; 
  left: 0;
}

.ml11 .letter {
  display: inline-block;
  line-height: 1em;
}

.ml12 .text-wrapper {
  position: relative;
  display: inline-block;
}

.ml12 .line_1 {
  opacity: 0;
  position: absolute;
  margin-top: 24px;
  left: 0;
  height: 60%;
  width: 3px;
  background-color: #fff;
  transform-origin: 0 50%;
}

.ml12 .line1 { 
  top: 0; 
  left: 0;
}

.ml12 .letter {
  display: inline-block;
  line-height: 1em;
  color: #dac3a5;
}

.ml11 .letter2 {
  display: inline-block;
  line-height: 1em;
}

.ml12 .letter2 {
  display: inline-block;
  line-height: 1em;
  color: #fff;
}

.letters2 {
  font-size: 50px;
  letter-spacing: 4px;
  font-size: 70px;
}

.letters3 {
  letter-spacing: 4px;
  font-size: 70px;
}

.ml11 {
  margin: 0;
  padding-bottom: 5px;
}

.ml12 {
  position: relative;
  margin: 0;
  padding-bottom: 50px;
  left: -3px;
}

.ml13 .text-wrapper {
  position: relative;
  display: inline-block;
}

.ml13 .line_2 {
  opacity: 0;
  position: absolute;
  margin-top: 12px;
  left: 0;
  height: 70%;
  width: 3px;
  background-color: #fff;
  transform-origin: 0 50%;
}

.ml13 .line1 { 
  top: 0; 
  left: 0;
}

.ml13 .letter {
  display: inline-block;
  line-height: 1em;
  color: #fff;
}

.ml13 .letter5 {
  color: #dac3a5;
}

.ml13 .letter6 {
  color: #fff;
}

.ml13 .letter7 {
  color: #dac3a5;
}

.ml13 .letter8 {
  color: #fff;
}

@media only screen and (max-width: 812px) {
  .home {
    min-width: 0;
    height: 101vh;
    min-height: 35em;
  }

  .introText h1 {
    margin: 0;
    letter-spacing: 3px;
    font-size: 17px;
    width: 80%;
  }

  .letters {
    font-size: 20px;
  }

  .letters3{
    letter-spacing: 1px;
    font-size: 55px;
  }
  
  .letters2{
    letter-spacing: 1px;
    font-size: 55px;
  }

  .scrollImage {  
    position: absolute;  
    height: 22px;
    width: 42px;
  }

  .scrollButton {
    top: 93%;
    left: 44%;
  }

  .ml11 .line {
    margin-top: 3.5px;
    height: 80%;
  }

  .ml12 .line_1 {
    margin-top: 14px;
    height: 70%;
  }

  .ml13 .line_2 {
    margin-top: 3px;
    height: 85%;
  }

}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .introText h1 {
      font-size: 15px;
    }

    .letters {
      font-size: 18px;
    }

    .letters3{
      font-size: 50px;
    }
    
    .letters2{
      font-size: 50px;
    }
  
    .scrollImage {  
      height: 20px;
      width: 38px;
    }
}
